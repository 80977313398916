<template>
  <v-card class="pa-6" color="accent">
    <v-card-text>
      <h3 class="text-h3 font-weight-bold mb-6">Your Details</h3>
      <v-row>
        <v-col>
          <h5 class="text-h5 mb-1">Username</h5>
          <v-text-field
              :value="currentUser.usersUid"
              disabled
              solo
          />
          <h5 class="text-h5 mb-1">Email</h5>
          <v-text-field
              :value="currentUser.usersEmail"
              disabled
              solo
              type="email"
          />
          <h5 class="text-h5 mb-1">Profile Description</h5>
          <v-textarea
              v-model.trim="newInfo.desc"
              :error-messages="descErrors"
              height="125px"
              solo
              @blur="$v.newInfo.desc.$touch()"
              @input="$v.newInfo.desc.$touch()"
          />
          <h5 class="text-h5 mb-1">Phone</h5>
          <v-text-field
              v-model.trim="newInfo.phone"
              :error-messages="phoneErrors"
              placeholder="Phone Number"
              solo
              step="1"
              type="number"
              @blur="$v.newInfo.phone.$touch()"
              @input="$v.newInfo.phone.$touch()"
          />
          <h5 class="text-h5 mb-1">Facebook</h5>
          <v-text-field
              v-model.trim="newInfo.facebook"
              :error-messages="facebookErrors"
              placeholder="Facebook Profile Link"
              solo
              type="url"
              @blur="$v.newInfo.facebook.$touch()"
              @input="$v.newInfo.facebook.$touch()"
          />
          <h5 class="text-h5 mb-1">Website</h5>
          <v-text-field
              v-model.trim="newInfo.website"
              :error-messages="websiteErrors"
              placeholder="Website Link"
              solo
              type="url"
              @blur="$v.newInfo.website.$touch()"
              @input="$v.newInfo.website.$touch()"
          />
          <h5 class="text-h5 mb-1">Address</h5>
          <v-row>
            <v-col cols="12" lg="6" md="6">
              <v-text-field
                  v-model.trim="newInfo.address"
                  placeholder="Address"
                  solo
                  @blur="$v.newInfo.address.$touch()"
                  @input="$v.newInfo.address.$touch()"
              />
            </v-col>
            <v-col cols="12" lg="6" md="6">
              <v-text-field
                  v-model.trim="newInfo.city"
                  placeholder="City"
                  solo
                  @blur="$v.newInfo.city.$touch()"
                  @input="$v.newInfo.city.$touch()"
              />
            </v-col>
            <v-col cols="6" lg="6" md="6">
              <v-text-field
                  v-model.trim="newInfo.postcode"
                  :error-messages="postcodeErrors"
                  placeholder="Postcode"
                  solo
                  step="1"
                  type="number"
                  @blur="$v.newInfo.postcode.$touch()"
                  @input="$v.newInfo.postcode.$touch()"
              />
            </v-col>
            <v-col cols="6" lg="6" md="6">
              <v-select
                  v-model.trim="newInfo.state"
                  :items="states"
                  placeholder="State"
                  solo
              />
            </v-col>
          </v-row>
          <h5 class="text-h5 mb-1">Preferred Contact</h5>
          <v-radio-group
              v-model.trim="newInfo.contactPref"
              :error-messages="contactPrefErrors"
              class="mt-2"
              mandatory
              row
              @blur="$v.newInfo.contactPref.$touch()"
              @input="$v.newInfo.contactPref.$touch()"
          >
            <v-radio :value="0" label="None"/>
            <v-radio :value="1" label="Email"/>
            <v-radio :value="2" label="Phone"/>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-btn class="white--text" color="primary" @click="editUserCall()"
          >Save Changes
          </v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import {helpers, maxLength, minLength, numeric, required, url} from "vuelidate/lib/validators";

const facebook = helpers.req(helpers.regex('url', /^https?:\/\/(www\.)?facebook\.com\/.+/));


export default {
  data() {
    return {
      newInfo: {
        desc: null,
        phone: null,
        facebook: null,
        website: null,
        address: null,
        city: null,
        postcode: null,
        state: null,
        contactPref: null,
      },
      states: ["NSW", "VIC", "ACT", "WA", "NT", "SA", "QLD", "TAS"],
    };
  },
  computed: {
    ...mapState('user', ["currentUser"]),
    descErrors() {
      const errors = [];
      if (!this.$v.newInfo.desc.$dirty) return errors;
      !this.$v.newInfo.desc.maxLength && errors.push("Description must be less than 490 Characters");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.newInfo.phone.$dirty) return errors;
      !this.$v.newInfo.phone.numeric && errors.push("Phone must be a number");
      !this.$v.newInfo.phone.minLength && errors.push("Phone must be 10 digits");
      !this.$v.newInfo.phone.maxLength && errors.push("Phone must be 10 digits");
      return errors;
    },
    facebookErrors() {
      const errors = [];
      if (!this.$v.newInfo.facebook.$dirty) return errors;
      !this.$v.newInfo.facebook.facebookVal && errors.push("Must be a valid HTTPS Facebook URL e.g https://www.facebook.com/example");
      return errors;
    },
    websiteErrors() {
      const errors = [];
      if (!this.$v.newInfo.website.$dirty) return errors;
      !this.$v.newInfo.website.websiteVal && errors.push("Website must be a valid HTTPS Website URL e.g. https://www.example.com");
      return errors;
    },
    postcodeErrors() {
      const errors = [];
      if (!this.$v.newInfo.postcode.$dirty) return errors;
      !this.$v.newInfo.postcode.numeric && errors.push("Postcode must be a number");
      !this.$v.newInfo.postcode.regex && errors.push("Postcode must be 10 digits");
      return errors;
    },
    contactPrefErrors() {
      const errors = [];
      if (!this.$v.newInfo.contactPref.$dirty) return errors;
      !this.$v.newInfo.contactPref.required && errors.push("Contact Preference is required");
      return errors;
    },
  },
  validations: {
    newInfo: {
      contactPref: {
        required,
      },
      desc: {
        optional: true,
        maxLength: maxLength(490),
      },
      facebook: {
        facebookVal: (value) => {
          if (value === "" || value === null || value === undefined) {
            return true; // Return true to indicate that the field is valid
          }
          // Apply the regex validation rule to the field
          const regexRule = helpers.regex('url', /^https?:\/\/(www\.)?facebook\.com\/.+/);
          return regexRule(value);
        },
      },
      phone: {
        optional: true,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      postcode: {
        optional: true,
        numeric,
        regex: helpers.regex(/^\d{10}$/),
      },
      website: {
        websiteVal: (value) => {
          if (value === "" || value === null || value === undefined) {
            return true; // Return true to indicate that the field is valid
          }
          // Apply the regex validation rule to the field
          const regexRule = helpers.regex('url', /^https?:\/\/.+/);
          return regexRule(value);
        },
      },
    },

  },
  watch: {
    "currentUser.usersPrefMethod": {
      immediate: true,
      handler(newVal) {
        this.newInfo.contactPref = newVal;
      },
    },
  },
  mounted() {
    // See if any of the currentUser values are null, if so, set them to empty string
    for (const [key, value] of Object.entries(this.currentUser)) {
      if (value === null || value === undefined || value === "null" || value === "undefined") {
        this.currentUser[key] = "";
      }
    }

    this.newInfo.desc = this.currentUser.usersDesc;
    this.newInfo.phone = this.currentUser.usersPhone;
    this.newInfo.facebook = this.currentUser.usersFacebook;
    this.newInfo.website = this.currentUser.usersWebsite;
    this.newInfo.contactPref = this.currentUser.usersPrefMethod;
    this.newInfo.address = this.currentUser.usersAddress;
    this.newInfo.city = this.currentUser.usersCity;
    this.newInfo.postcode = this.currentUser.usersPostCode;
    this.newInfo.state = this.currentUser.usersState;
  },
  methods: {
    ...mapActions('alert', ["alerts"]),
    ...mapActions('user', ["editUser"]),
    async editUserCall() {
      this.$v.$touch();
      window.scrollTo(0, 0);
      if (this.$v.$invalid) {
        await this.alerts({
          type: "error",
          msg: "Please fill in all the fields correctly.",
        });
        return;
      }
      let response = await this.editUser(this.newInfo);
      if (response["error"] === "none") {
        await this.alerts({
          type: "success",
          msg: "Changes made successfully.",
        });
      } else if (response["error"] === "invalidFacebook") {
        await this.alerts({
          type: "error",
          msg: "Please enter a valid Facebook URL",
        });
      } else if (response["error"] === "invaludWebsite") {
        await this.alerts({
          type: "error",
          msg: "Please enter a valid HTTPS Website URL e.g. https://www.example.com",
        });
      } else if (response["error"] !== "none") {
        await this.alerts({
          type: "error",
          msg: "An unknown error has occurred, please try again.",
        });
      }
    },
  },
};
</script>
