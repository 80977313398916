<template>
  <v-card class="px-6 py-4" :color="color" min-height="100%">
    <v-card-title>
      <div class="d-inline-flex my-3" :class="color === 'accent' ? '' : 'white--text'">
        <v-icon class="text-h4 mr-2" :class="color === 'accent' ? 'black--text' : 'white--text'">mdi-star</v-icon>
        <h4 class="text-h4 font-weight-bold text-break">
          My Subscription
        </h4>
      </div>
    </v-card-title>
    <div class="mb-5">
      <v-card>
        <v-card-text>
          <h5 class="text-h5 font-weight-bold">{{ name }}</h5>
          <h6 class="text-subtitle-1 font-weight-bold mb-4">Benefits</h6>
          <ul v-for="b in benefits" :key="b">
            <li>{{ b }}</li>
          </ul>
        </v-card-text>
      </v-card>
    </div>
    <div>
      <v-btn v-if="currentUser.usersTier === 0" block :to="{name: 'Subscriptions'}" color="primary">View Subscriptions</v-btn>
      <v-btn v-else :block="$vuetify.breakpoint.mobile" :class="!$vuetify.breakpoint.mobile ? 'float-right' : ''"
             @click="manageSub"
             color="primary" width="250">Manage Subscription
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
  props: ["color"],
  data() {
    return {
      name: "",
      benefits: [],
    };
  },
  computed: {
    ...mapState('user', ["currentUser"]),
  },
  mounted() {
    this.tierOrganiser();
  },
  methods: {
    ...mapActions('subscriptions', ['manageSub']),
    tierOrganiser() {
      switch (this.currentUser.usersTier) {
        case 0:
          this.name = "Private Seller"
          this.benefits = ["With a free account you can create 50 parts. You can upgrade your account to sell more parts, click the button below to view our subscription plans."]
          break;
        case 1:
          this.name = "Micro Business"
          this.benefits = ["Can sell 200 parts"]
          break;
        case 2:
          this.name = "Small Business"
          this.benefits = ["Can sell 500 parts"]
          break;
        case 3:
          this.name = "Medium Business"
          this.benefits = ["Can sell 1,000 parts"]
          break;
        case 4:
          this.name = "Large Business"
          this.benefits = ["Can sell 10,000 parts"]
          break;
        default:
          console.error("Error: User's Tier is unknown")
          break;
      }
    }
  },
};
</script>

<style>
</style>