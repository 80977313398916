<template>
  <v-container>
    <v-row class="mb-4">
      <v-col cols="12" lg="5" md="5">
        <v-row>
          <v-col>
            <PfpChange/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <SubCard color="accent"/>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <AccountDetails/>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import {mapActions} from 'vuex';
import MySub from "@/components/settings/MySub";
import SubCard from "@/components/dashboard/SubCard";
import PfpChange from "@/components/settings/PfpChange";
import AccountDetails from "@/components/settings/AccountDetails.vue";

export default {
  metaInfo: {
    title: "Account",
    "http-equiv": "Content-Security-Policy",
    content: "upgrade-insecure-requests",
  },
  components: {MySub, AccountDetails, PfpChange, SubCard},
  data() {
    return {
      tab: null,
    };
  },
  mounted() {
    this.pwdCheckCall();
  },
  methods: {
    ...mapActions('alert', ["alerts"]),
    ...mapActions('user', ["pwdCheck"]),
    async pwdCheckCall() {
      let response = await this.pwdCheck();
      if (response["error"] === "userNotFound") {
        this.alerts({
          type: "error",
          msg: "We had trouble finding the user, make sure you are logged in, or login again",
        });
      } else if (response["error"] === "stmtFailed") {
        this.alerts({
          type: "error",
          msg: "It seems something has failed on our end, try again or at a later time",
        });
      } else if (response["error"] === "noPostData") {
        this.alerts({
          type: "error",
          msg: "There was an issue sending data, please try again or login again",
        });
      }
      if (response["correct"] === true) {
      } else if (response["correct"] === false) {
        await this.$router.push({name: "Login"});
        this.alerts({
          type: "error",
          msg: "You must be logged in to access this page.",
        });
      }
    },
  },
};
</script>