<template>
  <v-card class="pa-6" color="accent">
    <v-card-title class="text-h3 font-weight-bold text-break">Profile Picture</v-card-title>
    <v-card-text class="mt-5">
      <v-row v-if="$vuetify.breakpoint.mobile">
        <v-col class="white rounded" cols="5">
          <v-avatar color="primary" size="100">
            <img
                :src="
                  baseURL +
                  '/storage/pfps/' +
                  currentUser.usersPfp
                "
                alt="Profile Picture"/>
          </v-avatar>
          <p>Current Picture</p>
        </v-col>
        <v-spacer/>
        <v-col class="white rounded" cols="5">
          <v-avatar color="grey" size="100">
            <v-img
                :src="tempUploadedImg"
            />
          </v-avatar>
          <p>New Picture</p>
        </v-col>
      </v-row>
      <!-- Desktop -->
      <v-row v-else>
        <v-col cols="6">
          <v-row class="float-right">
            <v-col class="white mr-4 rounded pb-8" cols="auto">
              <v-avatar color="primary" size="150">
                <img
                    :src="
                  baseURL +
                  '/storage/pfps/' +
                  currentUser.usersPfp
                "
                    alt="New Profile Picture"/>
              </v-avatar>
              <p>Current Picture</p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col class="white ml-4 rounded pb-8" cols="auto">
              <v-avatar color="grey" size="150">
                <v-img
                    :src="tempUploadedImg"
                />
              </v-avatar>
              <p>New Picture</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row no-gutters>
        <v-col>
          <v-file-input
              class="mt-5"
              label="Select a new profile picture here!"
              show-size
              solo
              truncate-length="50"
              @change="onSelectedFile"
          />
          <v-btn
              block
              class="white--text"
              color="primary"
              @click="changePfpCall"
          >Save Changes
          </v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "PfpChange",
  data() {
    return {
      image: null,
      tempUploadedImg: "",
    }
  },
  computed: {
    ...mapState(["baseURL"]),
    ...mapState('user', ["currentUser"])
  },
  methods: {
    ...mapActions('alert', ["alerts"]),
    ...mapActions('user', ["changePfp"]),
    onSelectedFile(event) {
      this.tempUploadedImg = "";
      this.image = event;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.tempUploadedImg = fileReader.result;
      });
      fileReader.readAsDataURL(event);
    },
    async changePfpCall() {
      let response = await this.changePfp({
        usersID: this.currentUser.usersID,
        image: this.image,
      });
      if (response["error"] === "none") {
        this.alerts({
          type: "success",
          msg: "Changes made successfully.",
        });
        this.pfpChange = false;
      } else if (response["error"] !== "none") {
        await this.alerts({
          type: "error",
          msg: "An unknown error has occurred, please try again.",
        });
      }
    },
  }
};
</script>

<style scoped>

</style>