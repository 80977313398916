<template>
  <v-card class="pa-6 grow" color="accent" flat>
    <v-card-title class="text-h4 font-weight-bold text-break">{{ tierName }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-btn block class="mb-4" color="primary">Change your subscription</v-btn>
          <v-btn block color="secondary">Cancel your subscription</v-btn>
        </v-col>
      </v-row>

    </v-card-text>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "MySub",
  data() {
    return {
      image: null,
      tempUploadedImg: "",
      pfpChange: false,
    }
  },
  computed: {
    ...mapState('user', ["currentUser"]),
    tierName() {
      switch (this.currentUser.usersTier) {
        case 0:
          return "Private Seller";
        case 1:
          return "Micro Business";
        case 2:
          return "Small Business";
        case 3:
          return "Medium Business";
        case 4:
          return "Large Business";
        default:
          console.log("Tier unknown: " + this.currentUser.usersTier)
          return "Unknown Tier"
      }
    }
  },
  methods: {
    ...mapActions('alert', ["alerts"]),
    ...mapActions('user', ["changePfp"]),
    onSelectedFile(event) {
      this.tempUploadedImg = "";
      this.image = event;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.tempUploadedImg = fileReader.result;
      });
      fileReader.readAsDataURL(event);
    },
    async changePfp() {
      let response = await this.changePfp({
        usersID: this.currentUser.usersID,
        image: this.image,
      });
      if (response["error"] === "none") {
        await this.alerts({
          type: "success",
          msg: "Changes made successfully.",
        });
        this.pfpChange = false;
      } else if (response["error"] !== "none") {
        alert("An error has occurred, please try again");
      }
    },
  }
};
</script>